import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [
        'update',
        'checkbox'
    ];

    private updateTargets: HTMLLinkElement[];
    private checkboxTargets: HTMLInputElement[];
    private rowCount: number;
    updateCountToHref(event: Event) {
        this.rowCount = 0;
        this.checkboxTargets.forEach((checkbox) => {
            if(checkbox.checked){
                this.rowCount++;
            }
        });

        this.updateTargets.forEach((element) => {
            if (element.href !== null) {
                const url = new URL(element.href);
                url.searchParams.set("count", "" + this.rowCount);
                element.href = url.href;
            }
        });
    }
}

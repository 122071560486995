import { Controller } from "stimulus";
import DataUtils from "./../util/data_utils";
import Spinner from "../util/spinner";

interface Props {
    bottomScrollDetectOffset: number;
}

interface State {
    isLoading: boolean;
    nextPageUrl: string;
    spinner: Spinner;
}

export default class extends Controller {
    static targets = [
        "item",
        "container",
        "stream",
        "loadingContainer",
        "pagination"
    ];

    itemTargets: HTMLElement[];
    containerTarget: any;
    streamTarget: HTMLElement;
    loadingContainerTarget: HTMLElement;
    paginationTarget: HTMLElement;

    hasPaginationTarget: boolean;
    hasContainerTarget: boolean;
    hasStreamTarget: boolean;
    private currentPage: HTMLElement;

    props: Props = {
        bottomScrollDetectOffset: 100
    };

    state: State = {
        isLoading: false,
        nextPageUrl: null,
        spinner: null
    };

    scrollEvent: any;

    async connect() {
        this.hidePagination();
        this.currentPage = <HTMLElement>this.element;
        this.state.spinner = new Spinner(this.loadingContainerTarget);
        this.state.nextPageUrl = this.getNextPageUrl();

        this.scrollEvent = async () => await this.scrollingObserver();

        if (this.hasContainerTarget) {
            this.containerTarget.scrollTop = 0;
            this.containerTarget.addEventListener("scroll", this.scrollEvent);
        } else {
            (<any>this.scrollContainer).addEventListener("scroll", this.scrollEvent);
        }

        this.element.dispatchEvent(new CustomEvent("stream:started"));

        if (this.autoscrollEnabled && this.nextPageIsAvailable()) {
            await this.loadNextPage();
        } else {
            await this.scrollingObserver();
        }

        if (this.nextPageIsAvailable()) {
            this.element.dispatchEvent(new CustomEvent("stream:loaded"));
        }

        if (!this.nextPageIsAvailable()) {
            this.element.dispatchEvent(new CustomEvent("stream:finished"));
        }
    }

    hidePagination() {
        if (this.hasPaginationTarget) this.paginationTarget.style.display = "none";
    }

    markAsIsLoading() {
        this.loadingContainerTarget.style.height = "100px";
        this.state.isLoading = true;
        this.displayLoadingAnimation();
    }

    markAsDoneLoading() {
        this.state.isLoading = false;
        this.state.spinner.stop();
        this.loadingContainerTarget.style.height = "0";
        this.element.dispatchEvent(new CustomEvent("stream:success"));
    }

    private async scrollingObserver() {
        this.state.nextPageUrl = this.getNextPageUrl();

        const shouldLoadNextPage =
            this.hasContainerTarget ?
                (this.state.isLoading === false && this.nextPageIsAvailable() && this.reachedBottomOfContainer())
                :
                (this.state.isLoading === false && this.nextPageIsAvailable() && this.reachedNearBottomOfPage());

        if (shouldLoadNextPage) await this.loadNextPage();
    }

    private reachedBottomOfContainer(): boolean {
        const scrollContainer = this.containerTarget;
        let scrollContainerBottom = scrollContainer.scrollHeight - scrollContainer.offsetHeight;

        if (this.hasStreamTarget)
            scrollContainerBottom = this.streamTarget.offsetHeight - scrollContainer.offsetHeight;

        return scrollContainer.scrollTop >= scrollContainerBottom;
    }

    private reachedNearBottomOfPage(): boolean {
        const scrollPosition = this.calculateScrollPosition(this.scrollContainer);
        const almostAtBottom = document.getElementById("content").clientHeight - this.props.bottomScrollDetectOffset;
        return scrollPosition > almostAtBottom;
    }

    private nextPageIsAvailable(): boolean {
        return !!this.state.nextPageUrl;
    }

    private async loadNextPage() {
        this.markAsIsLoading();

        await DataUtils.request(
            this.state.nextPageUrl, {}, { parse: false }
        ).then((response: Response) => {
            return response.text();
        }).catch(error => {
            // on server error its logged by rails
            // other (4xx or offline) errors could be handled through an error alert
            console.log(error);
        }).then((response: string) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(response, "text/html");

            return doc.body;
        }).then((htmlDoc: HTMLElement) => {
            this.addItems(htmlDoc);
            this.currentPage = htmlDoc;
            this.updateStreamState();
            if (this.autoscrollEnabled && this.nextPageIsAvailable()) {
                this.loadNextPage().catch(err => { console.log(err); });
            }
        });
    }

    private updateStreamState() {
        this.state.nextPageUrl = this.getNextPageUrl();

        if(this.nextPageIsAvailable()){
            this.element.dispatchEvent(new CustomEvent("stream:loaded"));
        }

        if (!this.nextPageIsAvailable()) {
            if (this.hasContainerTarget) this.containerTarget.removeEventListener("scroll", this.scrollEvent);
            else (<any>this.scrollContainer).removeEventListener("scroll", this.scrollEvent);

            this.element.dispatchEvent(new CustomEvent("stream:finished"));
        }
    }

    private displayLoadingAnimation() {
        this.loadingContainerTarget.classList.remove("hidden");
        this.state.spinner.start();
    }

    private getNextPageUrl() {
        const nextPage = this.currentPage.querySelector("[data-role='pagination'] a[rel='next']");
        if (nextPage) {
            return nextPage.getAttribute("href");
        } else {
            return null;
        }
    }

    private addItems(htmlDoc: HTMLElement) {
        htmlDoc.querySelectorAll(`[data-target*='${this.identifier}.item']`).forEach(child => {
            this.itemTargets[0].parentNode.appendChild(child);
        });
        this.markAsDoneLoading();
    }

    private get scrollContainer() {
        if (document.getElementsByClassName("syn_page-layout__body")[0] != undefined) {
            return document.getElementsByClassName("syn_page-layout__body")[0];
        } else {
            return window;
        }
    }

    private calculateScrollPosition(element: Element | Window): number {
        if (element == window) {
            return document.documentElement.scrollTop + (<any>window).innerHeight;
        } else {
            return (<Element>element).scrollTop + (<Element>element).clientHeight;
        }
    }

    private get autoscrollEnabled(): boolean {
        return !!this.data.get("autoscrollEnabled");
    }
}

import Sentry from "@utils/sentry";
import DataUtils from '@utils/data_utils';
import DomUtils from '@utils/dom_utils';
import { selectizeOf } from '@utils/selectize';
import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [
        'localeSelect',
        'listContainer',
        'emptyMessage',
        'localeForm',
        'localeTranslators',
        'localeReviewers',
        'addAllLocalesButton',
        'translatorTemplate',
        'reviewerTemplate'
    ];

    private localeSelectTarget: HTMLSelectElement;
    private listContainerTarget: HTMLDivElement;
    private emptyMessageTarget: HTMLDivElement;
    private localeFormTargets: HTMLDivElement[];
    private translatorTemplateTarget: HTMLTemplateElement;
    private reviewerTemplateTarget: HTMLTemplateElement;
    private localeTranslatorsTargets: HTMLDivElement[];
    private localeReviewersTargets: HTMLDivElement[];
    private addAllLocalesButtonTarget: HTMLButtonElement;

    connect() {
        this.localeSelectTarget.addEventListener("jqueryChange", this.addSelectedLocale.bind(this));
    }

    setupLocales() {
        [
            [this.localeTranslatorsTargets, this.translatorTemplate, this.translatorTemplateTarget],
            [this.localeReviewersTargets, this.reviewerTemplate, this.reviewerTemplateTarget]
        ].forEach((list: Array<any>) => {
            list[0].forEach((el, index) => { this.setupLocale(el, index, list[1], list[2]); });
        });
    }

    setupLocale(localeAssignment, index, template, templateReference) {
        if (localeAssignment.nextSibling !== null) return;

        const element = template.cloneNode(true) as HTMLElement;
        const usersList = element.querySelectorAll("[data-controller*='syntax-list-multiselect']")[0];
        const teamsList = element.querySelectorAll("[data-controller*='syntax-list-multiselect']")[1];
        const selectedUsers = (localeAssignment.dataset.users) ? JSON.parse(localeAssignment.dataset.users) : [];
        const selectedTeams = (localeAssignment.dataset.teams) ? JSON.parse(localeAssignment.dataset.teams) : [];

        element.setAttribute("data-index", index.toString());

        if (usersList && usersList.firstElementChild) {
            selectedUsers.forEach((code) => {
                const user = templateReference.content.querySelector(`[data-value='${code}']`).cloneNode(true) as HTMLElement;
                user.querySelectorAll("[name*='[id]']").forEach((idInput: HTMLInputElement) => {
                    idInput.name = idInput.name.replace("[id]", `[${index}]`);
                    idInput.checked = true;
                });
                usersList.firstElementChild.prepend(user);
            });
        }

        if (teamsList && teamsList.firstElementChild) {
            selectedTeams.forEach((code) => {
                const team = templateReference.content.querySelector(`[data-value='${code}']`).cloneNode(true) as HTMLElement;
                team.querySelectorAll("[name*='[id]']").forEach((idInput: HTMLInputElement) => {
                    idInput.name = idInput.name.replace("[id]", `[${index}]`);
                    idInput.checked = true;
                });
                teamsList.firstElementChild.prepend(team);
            });
        }

        localeAssignment.after(element);
    }

    openLocale(event) {
        const dropdown = event.currentTarget.closest("[data-controller*='syntax-dropdown']");
        const select = dropdown.parentElement;
        const index = select.dataset.index;
        const type = select.dataset['jobs-LocaleFormSelectName'];
        const locale = select.previousSibling;

        const usersList = dropdown.querySelectorAll("[data-controller*='syntax-list-multiselect']")[0];
        const teamsList = dropdown.querySelectorAll("[data-controller*='syntax-list-multiselect']")[1];
        const availableMembers = (locale.dataset.members) ? JSON.parse(locale.dataset.members) : null;
        const selectedUsers = (locale.dataset.users) ? JSON.parse(locale.dataset.users) : [];
        const selectedTeams = (locale.dataset.teams) ? JSON.parse(locale.dataset.teams) : [];

        if (locale.dataset.loaded) return;

        const template = type === "translators" ? this.copyTranslatorTemplate() : this.copyReviewerTemplate();

        template.querySelectorAll("[name*='[id]']").forEach((idInput: HTMLInputElement) => {
            const button = idInput.closest("button");
            idInput.name = idInput.name.replace("[id]", `[${index}]`);
            if (selectedUsers.includes(idInput.value) || selectedTeams.includes(idInput.value)) button.remove();

            if (!idInput.name.includes("team") && availableMembers && !availableMembers.includes(idInput.value)) button.remove();
        });

        const items = [[usersList, template.querySelectorAll("[data-controller*='syntax-list-multiselect']")[0]]];
        if (teamsList) items.push([teamsList, template.querySelectorAll("[data-controller*='syntax-list-multiselect']")[1]]);

        items.forEach((list) => {
            list[1].querySelectorAll("div > button").forEach((el) => {
                list[0].firstElementChild.append(el);
            });
        });

        if (selectedUsers.length > 0) usersList.querySelector("[data-target*='.divider']").classList.remove("hidden");
        if (selectedTeams.length > 0) teamsList.querySelector("[data-target*='.divider']").classList.remove("hidden");


        locale.setAttribute("data-loaded", "true");
    }

    updateLocales() {
        if (this.localeFormTargets.length == 0) {
            DomUtils.showElement(this.emptyMessageTarget);
        }

        const selectableLocales = this.selectableLocales();
        selectizeOf(this.localeSelectTarget)
            .then(selectize => {
                selectize.clear(true);
                selectize.clearOptions();

                selectableLocales.forEach(locale => {
                    selectize.addOption(
                        {
                            value: locale[1],
                            text: locale[0],
                        }
                    );
                });

                selectize.refreshOptions(false);
                this.updateAllLocalesButtonState();
            })
            .catch(err => {
                Sentry.notify(err);
            });
    }

    updateGlobalLocales() {
        if (this.localeFormTargets.length == 0) {
            DomUtils.showElement(this.emptyMessageTarget);
        }

        const selectableLocales = this.selectableLocales();
        selectizeOf(this.localeSelectTarget)
            .then(selectize => {
                selectize.clear(true);
                selectize.clearOptions();

                selectableLocales.forEach(locale => {
                    selectize.addOption(
                        {
                            value: locale[0] + ":" + locale[1],
                            text: locale[0] + " (" + locale[1] + ")",
                        }
                    );
                });

                selectize.refreshOptions(false);
                this.updateAllLocalesButtonState();
            })
            .catch(err => {
                Sentry.notify(err);
            });
    }

    addAllLocales(event: Event) {
        event.preventDefault();

        const promises = this.selectableLocales().map((locale) => {
            const localeId = locale[1];
            return this.addLocale(localeId);
        });

        return Promise.all(promises)
            .then(() => {
                this.updateAllLocalesButtonState();
            })
            .catch(err => {
                Sentry.notify(err);
            });
    }

    addAllGlobalLocales(event: Event) {
        event.preventDefault();

        const promises = this.selectableLocales().map((locale) => {
            const localeId = locale[0] + ":" + locale[1];
            return this.addLocale(localeId);
        });

        return Promise.all(promises)
            .then(() => {
                this.updateAllLocalesButtonState();
            })
            .catch(err => {
                Sentry.notify(err);
            });
    }

    preventDoubleClicks() {
        this.addAllLocalesButtonTarget.disabled = true;
    }

    // Update index for each select
    updateIndex() {
        this.localeFormTargets.forEach((localeForm, index) => {
            // for old selects
            localeForm
                .querySelectorAll(".selectized")
                .forEach((selectizedSelect: HTMLSelectElement) => {
                    selectizedSelect.setAttribute('name', selectizedSelect.name.replace('[0]', `[${index}]`));
                    selectizedSelect.setAttribute('id', selectizedSelect.id.replace('[0]', `[${index}]`));
                });

            // for new selects
            const localeNameInput = localeForm.querySelector('#job_template_job_template_locales_id_locale_name') as HTMLInputElement;
            localeNameInput?.setAttribute('name', localeNameInput.name.replace('[0]', `[${index}]`));

            // for global templates
            const localeNameAndCodeInput = localeForm.querySelector('#job_template_job_template_locales_id_locale_name_and_code') as HTMLInputElement;
            localeNameAndCodeInput?.setAttribute('name', localeNameAndCodeInput.name.replace('[0]', `[${index}]`));

            const localeIdInput = localeForm.querySelector('#job_template_job_template_locales_id_id') as HTMLInputElement;
            localeIdInput?.setAttribute('name', localeIdInput.name.replace('[0]', `[${index}]`));

            // for jobs
            const jobLocaleIdInput = localeForm.querySelector('#job_job_locales_id_id') as HTMLInputElement;
            jobLocaleIdInput?.setAttribute('name', jobLocaleIdInput.name.replace('[0]', `[${index}]`));

            const jobLocaleIdLocaleIdInput = localeForm.querySelector('#job_job_locales_id_locale_id') as HTMLInputElement;
            jobLocaleIdLocaleIdInput?.setAttribute('name', jobLocaleIdLocaleIdInput.name.replace('[0]', `[${index}]`));

            localeForm.setAttribute('data-jobs--locale-form-counter', index.toString());
            localeForm
                .querySelectorAll(".syn_select__wrapper input[type='checkbox']")
                .forEach((userOrReviewerSelect: HTMLSelectElement) => {
                    userOrReviewerSelect.setAttribute('name', userOrReviewerSelect.name.replace('[0]', `[${index}]`));
                    userOrReviewerSelect.setAttribute('id', userOrReviewerSelect.id.replace('[0]', `[${index}]`));
                });
        });
    }

    private updateAllLocalesButtonState() {
        const selectableLocales = this.selectableLocales();
        if (selectableLocales.length === 0) {
            this.addAllLocalesButtonTarget.disabled = true;
        } else {
            this.addAllLocalesButtonTarget.disabled = false;
        }
    }

    private addSelectedLocale() {
        const localeId = this.localeSelectTarget.value;
        this.addLocale(localeId, true)
            .catch(err => {
                Sentry.notify(err);
            });
    }

    private addLocale(localeId: string, updateButtonState = false) {
        const queryParams = {};
        queryParams[this.localeIdentifier] = localeId;
        if (this.jobId) {
            queryParams['job_id'] = this.jobId;
        }

        DomUtils.hideElement(this.emptyMessageTarget);
        this.preventDoubleClicks();

        selectizeOf(this.localeSelectTarget)
            .then(selectize => {
                selectize.removeOption(localeId, true);
            })
            .catch(err => {
                Sentry.notify(err);
            });

        return DataUtils.request(
            this.localePartialPath,
            queryParams,
            { parse: false },
            {
                credentials: "same-origin",
                method: "GET",
                headers: {
                    "Accept": "text/html",
                    "X-Requested-With": "fetch"
                }
            },
        )
            .then(async response => {
                const html = await response.text();
                this.insertLocaleRow(html);
                if (updateButtonState) {
                    this.updateAllLocalesButtonState();
                }
                return response;
            })
            .catch(err => {
                Sentry.notify(err);
            });
    }

    private insertLocaleRow(html: string) {
        const localeElement = document.createElement('div');

        localeElement.innerHTML = html;

        const localeForm = localeElement.firstElementChild;
        const count = this.selectedLocaleCodes().length;

        localeForm.setAttribute('data-jobs--locale-form-counter', count.toString());

        const translators = localeForm.querySelector("[data-target='jobs--assign-locales.localeTranslators']");
        const reviewers = localeForm.querySelector("[data-target='jobs--assign-locales.localeReviewers']");

        if (translators)
            this.setupLocale(translators, count, this.translatorTemplate, this.translatorTemplateTarget);
        if (reviewers)
            this.setupLocale(reviewers, count, this.reviewerTemplate, this.reviewerTemplateTarget);

        // update id and name attribute of locale form select elements
        localeForm
            .querySelectorAll('select')
            .forEach((userOrReviewerSelect) => {
                userOrReviewerSelect.setAttribute('name', userOrReviewerSelect.name.replace('[0]', `[${count.toString()}]`));
                userOrReviewerSelect.setAttribute('id', userOrReviewerSelect.id.replace('[0]', `[${count.toString()}]`));
            });

        this.listContainerTarget.appendChild(localeForm);

        localeElement.remove();
    }

    private allLocales(): string[][] {
        return JSON.parse(this.data.get('locales'));
    }

    private get localePartialPath() {
        return this.data.get('locale-partial-path');
    }

    private get jobId() {
        return this.data.get('job-id');
    }

    private get localeIdentifier() {
        return this.data.get('locale-identifier') || 'locale_id';
    }

    private selectedLocaleCodes(): string[] {
        return this.localeFormTargets.map(locale => locale.getAttribute('data-jobs--locale-form-locale-id'));
    }

    private selectableLocales(): string[][] {
        return this.allLocales()
            .filter(localeName => !this.selectedLocaleCodes().includes(localeName[1]))
            .filter(localeName => !this.selectedLocaleCodes().includes(localeName[0] + ":" + localeName[1]));
    }

    private copyTranslatorTemplate(): HTMLElement {
        return this.translatorTemplateTarget.content.cloneNode(true).firstChild as HTMLElement;
    }

    private copyReviewerTemplate(): HTMLElement {
        return this.reviewerTemplateTarget.content.cloneNode(true).firstChild as HTMLElement;
    }

    private get translatorTemplate(): HTMLElement {
        const template = this.copyTranslatorTemplate();
        template.querySelector("[data-target*='syntax-dropdown.anchor']").setAttribute("data-action", "click->syntax-dropdown#toggleDropdown click->jobs--assign-locales#openLocale");
        template.querySelectorAll("[data-controller*='syntax-list-multiselect'] > div").forEach((el) => {
            const divider = el.querySelector("[data-target*='.divider']");
            el.replaceChildren(divider);
        });
        return template;
    }

    private get reviewerTemplate(): HTMLElement {
        const template = this.copyReviewerTemplate();
        template.querySelector("[data-target*='syntax-dropdown.anchor']").setAttribute("data-action", "click->syntax-dropdown#toggleDropdown click->jobs--assign-locales#openLocale");
        template.querySelectorAll("[data-controller*='syntax-list-multiselect'] > div").forEach((el) => {
            const divider = el.querySelector("[data-target*='.divider']");
            el.replaceChildren(divider);
        });
        return template;
    }
}

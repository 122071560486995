import Sentry from '@utils/sentry';
import i18next from 'i18next';

import localeEN from './locales/i18n.en.json';
import localeDE from './locales/i18n.de.json';
import localeCS from './locales/i18n.cs.json';
import localeES from './locales/i18n.es.json';
import localeFR from './locales/i18n.fr.json';
import localeIT from './locales/i18n.it.json';
import localeJA from './locales/i18n.ja.json';
import localeKO from './locales/i18n.ko.json';
import localePT from './locales/i18n.pt.json';
import localeRU from './locales/i18n.ru.json';
import localeZHCN from './locales/i18n.zh-CN.json';

// we store the current locale on the <html> node
const documentLanguage = document.documentElement.getAttribute('lang');

i18next
    .init({
        lng: documentLanguage,
        fallbackLng: 'en',
        supportedLngs: (window as any).supportedLanguages,
        preload: (window as any).supportedLanguages,
        ns: 'global',
        defaultNS: 'global',
        fallbackNS: false,
        detection: {
            order: ['htmlTag']
        },
        interpolation: {
            prefix: '%{',
            suffix: '}'
        }
    },
    (err) => {
        if (err) {
            Sentry.notify(err);
        }
    }
    ).catch(err => {
        Sentry.notify(err);
    });

i18next.addResourceBundle('en', 'global', localeEN);
i18next.addResourceBundle('de', 'global', localeDE);
i18next.addResourceBundle('cs', 'global', localeCS);
i18next.addResourceBundle('es', 'global', localeES);
i18next.addResourceBundle('fr', 'global', localeFR);
i18next.addResourceBundle('it', 'global', localeIT);
i18next.addResourceBundle('ja', 'global', localeJA);
i18next.addResourceBundle('ko', 'global', localeKO);
i18next.addResourceBundle('pt', 'global', localePT);
i18next.addResourceBundle('ru', 'global', localeRU);
i18next.addResourceBundle('zh-CN', 'global', localeZHCN);

// expose i18n to window so it can be used by old modal code
// -> app/assets/javascripts/translation-center/ui/modal_window/base.js
(<any>window).I18n = i18next;
export { i18next as i18n };
